import Menu from '../Menu';
import './index.css';

const Header = () => {
  return (
    <div className='header'>
      <Menu />
    </div>
  )
}

export default Header
