import React from 'react'
import Header from '../../components/Header';
import ReactMarkdown from 'react-markdown'
import Content from '../../components/Content';
import Container from '../../components/Container';
import './index.css';

interface ArticleType {
  markdown: string;
}

const Article = ({ markdown }: ArticleType) => {
  const [content, setContent] = React.useState('')

  React.useEffect(() => {
    fetch(markdown)
      .then(res => res.text())
      .then(text => setContent(text))
  }, [markdown])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <ReactMarkdown>
            {content}
          </ReactMarkdown>
        </Content>
      </Container>
    </>
  )
}

export default Article
