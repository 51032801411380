import Header from '../components/Header';
import Container from '../components/Container';
import Content from '../components/Content';
import LineBreak from '../components/LineBreak';
import ArticleLink from '../components/ArticleLink';
import Text from '../components/Text';

const Blog = () => {
  const text = "This blog is about management #m8t. You'll find tips about running 1 to 1, interviews, hiring, performance reviews (and so on) in the context of a software engineering team."

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Text text={text} />
          <LineBreak />
          <ArticleLink to='/blog/just-say-thank-you' text='Just say "thank you" 🙏‍' date='Wed, Apr 27th 2022' />
          <ArticleLink to='/blog/onboarding' text='Goodbye pre-onboarding, hello onboarding' date='Sat, May 21st 2022' />
          <ArticleLink to='/blog/reorg-talks' text='Reorg? Talk to your mates.' date='Fri, May 24th 2024' />
          {/*<ArticleLink to='/blog/take-meeting-notes' text='Take notes.. not on your laptop ✏️' />*/}
          {/*<ArticleLink to='/blog/action-items' text='Do not leave without action items ✅' />*/}
        </Content>
      </Container>
    </>
  )
}

export default Blog
