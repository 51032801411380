import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/Home';
import Blog from './pages/Blog';
import Article from "./components/Article";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

// Articles
import JustSayThankYou from "./articles/just_say_thank_you.md"
import Onboarding from "./articles/onboarding.md"
import ReorgTalks from "./articles/reorg_talks.md"
// import TakeNotes from "./articles/take_notes.md"
// import ActionItems from "./articles/action_items.md"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/just-say-thank-you" element={<Article markdown={JustSayThankYou} />} />
        <Route path="blog/onboarding" element={<Article markdown={Onboarding} />} />
        <Route path="blog/reorg-talks" element={<Article markdown={ReorgTalks} />} />
        {/* <Route path="blog/take-meeting-notes" element={<Article markdown={TakeNotes} />} />*/}
        {/* <Route path="blog/action-items" element={<Article markdown={ActionItems} />} />*/}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
