import './index.css';

interface textType {
  text: string;
  link?: string;
  linkText?: string;
  type?: string;
}

const Text = ({ text, link, linkText, type }: textType) => {
  const insertLinkInline = () => {
    const href = `<a target='_blank' class=${type} href=${link}>${linkText}</a>`
    const newText = text.replace('{link}', `${href}`)

    return <span dangerouslySetInnerHTML={{ __html: newText }} />
  }

  const content = link ? insertLinkInline() : text

  return (
    <div className='text'>
      {content}
    </div>
  )
}

export default Text
