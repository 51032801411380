import React from 'react';
import Text from '../components/Text';
import Header from '../components/Header';
import Container from '../components/Container';
import logo from '../memoji.png';
import './Home.css';

function App() {
  const title = 'Hi!'
  const name = 'My name is Edouard,'
  const intro = 'I live in Barcelona with Yuumi and our sons Keijyu & Souzen,'
  const tennis = "I enjoy sports like padel, squash and tennis."
  const work = "I work at {link} as an engineering manager,"
  const contact = "Reach me at {link},"
  const cheers = 'Cheers, 👋'

  return (
    <>
      <Header />
      <Container>
        <div className="content">
          <img src={logo} className="logo" alt="logo" />
          <Text text={title} />
          <Text text={name} />
          <Text text={intro} />
          <Text text={tennis}/>
          <Text text={work} link='https://www.factorialhr.com/' linkText='Factorial' type='red'/>
          <Text text={contact} link='mailto:edouard.garret@proton.me' linkText='edouard.garret@proton.me' type='blue'/>
          <Text text={cheers} />
        </div>
      </Container>
    </>
  );
}

export default App;
