import './index.css'

const Content = ({ children }: any) => {
  return (
    <div className='content'>
      {children}
    </div>
  )
}

export default Content
