import { NavLink } from "react-router-dom";
import colors from '../../lib/colors';
import './index.css';

const Menu = () => {
  const isActiveStyle = {
    textDecoration: 'underline',
    listStyleType: 'none',
    color: 'white',
    textDecorationColor: 'white',
    textDecorationThickness: '5px',
    textUnderlineOffset: '5px'
  }

  const isNormalStyle = {
    textDecoration: 'none',
    listStyleType: 'none',
    color: colors.blue
  }

  return (
    <div className='menu'>
      <NavLink
        style={({ isActive }) => isActive ? isActiveStyle : isNormalStyle}
        to="/"
      >
        <li>Home</li>
      </NavLink>
      <NavLink
        style={({ isActive }) => isActive ? isActiveStyle : isNormalStyle}
        to="/blog"
      >
        <li>Blog</li>
      </NavLink>
    </div>
  )
}

export default Menu
