import { Link } from "react-router-dom";
import colors from '../../lib/colors';

interface ArticleLinkType {
  to: string;
  text: string;
  date: string;
}

const ArticleLink = ({ to, text, date }: ArticleLinkType) => {
  const formattedDate = " - " + date

  return (
    <span>
      <Link
        style={{ textDecoration: 'none', lineHeight: '2.5', color: colors.blue }}
        to={to}
      >
        {text}
      </Link>
      <span>{formattedDate}</span>
    </span>
  )
}

export default ArticleLink
